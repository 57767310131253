import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/worldcheck-case'

class WorldcheckService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    index(page, params, status) {
        var queryUrl = ''
        if (status) {
            queryUrl = `?status=` + status;
        }

        if (params && page) {
            let statusUrl = '';
            if (status) {
                statusUrl = `&status=` + status;
            }
            const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            queryUrl = `?page=` + page + statusUrl + `&` + queryParams
        }
        return http.get(`${this.getUrl()}` + queryUrl)
    }
    download(id){
        return http.get(`${this.getUrl()}/download/${id}`, {'responseType': 'arraybuffer'})
    }
}

export default new WorldcheckService()